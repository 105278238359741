'use client';

import { shortenNumber } from '@/src/lib';
import { HomeService } from '@/src/lib/api/v3';
import { AllTimeStats } from '@/src/types';
import { useEffect, useState } from 'react';
import CurrencyFormattedNumber from '../CurrencyNumber';
import { builder } from '@builder.io/sdk';
import config from '@/src/config';

builder.init(config().buiderIoApiKey);

export default function AllTimeStats() {
  const [stats, setStats] = useState<AllTimeStats>();

  useEffect(() => {
    HomeService.footerStats().then((res) => {
      const { success, message, result } = res;
      if (!success) {
        console.error('Failed to fetch all time stats', message);
        return;
      }

      const { total_campaigns, total_countries, total_funded, total_users } =
        result;

      setStats({
        total_users,
        total_campaigns,
        total_funded,
        total_countries,
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {stats && (
        <div className="flex gap-4 justify-around mb-2">
          <div>
            <h3 className="text-base font-bold tracking-tight mb-1">
              {shortenNumber(stats.total_users)}
            </h3>
            <p className="text-sm">Donors</p>
          </div>
          <div>
            <h3 className="text-base font-bold tracking-tight mb-1">
              {shortenNumber(stats.total_campaigns)}
            </h3>
            <p className="text-sm">Campaigns</p>
          </div>
          <div>
            <CurrencyFormattedNumber
              element="h3"
              className="text-base font-bold tracking-tight mb-1"
              amount={Number(stats.total_funded)}
              currencyCode="USD"
              shortenNumber={true}
            />
            <p className="text-sm">Funded</p>
          </div>
          <div>
            <h3 className="text-base font-bold tracking-tight mb-1">
              {stats.total_countries}
            </h3>
            <p className="text-sm">Countries</p>
          </div>
        </div>
      )}
    </>
  );
}
